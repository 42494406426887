.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: 0.69em;
  background-color: #008827;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

.refinement-bar {
  overflow: auto; }
  .refinement-bar ul {
    padding-left: 0; }
  @media (max-width: 768.98px) {
    .refinement-bar {
      background-color: #fff;
      box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
      display: none;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1; } }
  @media (max-width: 543.98px) {
    .refinement-bar {
      position: fixed;
      height: 100%; } }
  @media (min-width: 769px) {
    .refinement-bar {
      display: block !important; } }

.filter-header {
  margin-left: -15px;
  margin-right: -15px; }

.header-bar {
  background-color: #eee;
  border-bottom: 0.063em solid #eee; }
  .header-bar button.close {
    font-size: 1rem;
    font-weight: normal;
    opacity: 1; }
  .header-bar .fa.fa-close::before {
    font-size: 1.25em; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .header-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .header-bar {
      padding: 0.938em; } }

.refinements .header,
.refinements .values {
  padding: 0.313em 0; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .refinements .header li,
    .refinements .values li {
      display: inline-block; } }

.refinements .header {
  font-size: 1.125em;
  color: #444; }

.refinements ul {
  overflow: auto; }
  .refinements ul li {
    padding-left: 1px; }
    .refinements ul li button {
      border: none;
      color: var(--skin-link-color-1);
      background-color: transparent; }

.refinements li {
  list-style-type: none;
  padding-left: 0; }
  .refinements li.color-attribute {
    display: inline-block;
    padding-left: 0; }
    .refinements li.color-attribute button {
      padding: 0; }
  .refinements li.disabled {
    opacity: 0.5; }

@media (max-width: 543.98px) {
  .refinements {
    padding: 0; } }

.refinements .card-header h2 {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0; }

.secondary-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  overflow: auto; }
  @media (max-width: 768.98px) {
    .secondary-bar button.reset {
      float: right; } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .secondary-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .secondary-bar {
      padding: 0.938em; } }

.refinement-header {
  overflow: auto; }

.disabled {
  pointer-events: none; }

.grid-header,
.content-grid-header {
  margin-top: 1em;
  margin-bottom: 1em; }
  @media (max-width: 543.98px) {
    .grid-header .result-count,
    .content-grid-header .result-count {
      padding-bottom: 0.938em; }
    .grid-header .filter-results,
    .content-grid-header .filter-results {
      display: block;
      width: 100%; } }
  .grid-header select,
  .content-grid-header select {
    width: 100%; }

.result-count {
  font-size: 0.875rem; }

.refinement {
  margin-bottom: 1em; }
  @media (max-width: 768.98px) {
    .refinement.card {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-radius: 0;
      margin-bottom: 0; }
    .refinement .card-header {
      border-bottom: 0 none;
      padding: 0.25rem 1rem; }
    .refinement .card-body {
      padding: 0 1rem; } }
  .refinement a {
    text-decoration: none; }
  .refinement.refinement-category li {
    margin-left: 0.938em;
    display: block; }
  .refinement.refinement-category > ul > li {
    margin-left: 0; }

.search-banner {
  background-image: url("../images/search.jpg");
  background-position-y: 40%; }

.search-keywords {
  font-weight: bold; }

.filter-bar ul {
  padding-left: 15px; }

.filter-bar li {
  list-style: none;
  float: left;
  margin-left: 0.3125em;
  margin-right: 0.3125em; }
  .filter-bar li:first-child {
    margin-left: 0; }
  .filter-bar li.filter-value {
    border: 1px solid #ccc;
    padding: 0.3125em;
    border-radius: 3px;
    position: relative; }
    .filter-bar li.filter-value button {
      color: black;
      border: none;
      padding: 0 2rem 0 0.5rem; }
      .filter-bar li.filter-value button::after {
        content: "\f00d";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        position: absolute;
        right: 10px;
        top: 11px; }
  .filter-bar li.swatch-filter {
    font-size: 1.38em;
    position: relative;
    margin-top: 4px; }
    .filter-bar li.swatch-filter button {
      width: 100%;
      height: 100%;
      display: block;
      border: none; }
      .filter-bar li.swatch-filter button::before {
        content: "";
        background-color: white;
        width: 0.8em;
        height: 0.8em;
        border-radius: 0.4em;
        position: absolute;
        left: 0.5em;
        top: 0.5em; }
      .filter-bar li.swatch-filter button::after {
        content: "\f057";
        font: normal normal normal 14px/1 FontAwesome;
        display: inline;
        text-rendering: auto;
        position: absolute;
        left: 0.18em;
        top: 0.12em;
        font-size: 1.5em; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0.938em; }
    .product-grid .col-6 {
      padding-left: 5px;
      padding-right: 5px; } }

.search-tips {
  text-align: left;
  margin-top: 1.875rem; }

.category-item {
  margin-bottom: 0.938rem; }

.show-more,
.show-more-content {
  padding: 0.938em;
  clear: both; }

.swatch-mark.color-value[data-selected=true]::after {
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  left: 0.295em;
  position: absolute; }

.swatch-mark.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em; }

.search-nav {
  margin-bottom: 1em;
  border-bottom: 0.063em solid #ccc; }
  .search-nav .nav-tabs-wrapper {
    padding: 0; }
    @media (max-width: 543.98px) {
      .search-nav .nav-tabs-wrapper {
        width: 100%; } }
    .search-nav .nav-tabs-wrapper .nav-tabs {
      border-bottom: 0 none; }
  .search-nav .nav-link:focus {
    background-color: #f9f9f9; }

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-square-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-square-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-square-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-square-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-square-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: 0.69em;
  background-color: #008827;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-square-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-square-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-square-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-square-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-square-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-square-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-square-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-square-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous,
.swatch-square-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled,
  .swatch-square-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after,
  .swatch-square-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.swatch-square-miscellaneous {
  border-radius: 0;
  transform: unset; }
  .swatch-square-miscellaneous.selected::after {
    transform: unset; }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.compare-bar-wrapper {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s ease; }
  .compare-bar-wrapper .toggle-compare {
    position: absolute;
    right: 1.125rem;
    top: -2.1875rem;
    background-color: var(--color-grey1);
    border: 1px solid var(--color-hr-border-color);
    border-bottom: 0; }
    .compare-bar-wrapper .toggle-compare .hide-text {
      display: inline; }
    .compare-bar-wrapper .toggle-compare .show-text {
      display: none; }
  .compare-bar-wrapper.hidden {
    bottom: -8.25rem; }
    .compare-bar-wrapper.hidden .toggle-compare .hide-text {
      display: none; }
    .compare-bar-wrapper.hidden .toggle-compare .show-text {
      display: inline; }
  .compare-bar-wrapper .compare-bar {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .compare-bar-wrapper .product-slots {
    height: 6.25em; }
  .compare-bar-wrapper .slot {
    background-color: var(--color-white);
    height: 5em;
    margin: 0 auto;
    position: relative;
    width: 6.788em; }
    .compare-bar-wrapper .slot:not([data-pid]) {
      border: 1px dashed #ccc; }
    .compare-bar-wrapper .slot img {
      float: left;
      height: 100%;
      position: static; }
    .compare-bar-wrapper .slot i {
      color: #666;
      float: left;
      font-size: 1rem;
      position: relative;
      top: 50%;
      transform: translate(65%, -50%); }
    .compare-bar-wrapper .slot .close {
      background-color: #eee;
      height: 100%;
      width: 25%;
      opacity: 1; }
  .compare-bar-wrapper .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .compare-bar-wrapper .action .btn {
      width: 100%; }
  .compare-bar-wrapper .clear-all {
    margin-top: 0.625rem; }
  .compare-bar-wrapper .min-products-msg {
    color: #fff;
    font-size: 0.75em;
    line-height: 1.375rem;
    padding: 0.438rem; }

.product-comparison .back-to-results {
  margin: 1em 0; }

.product-comparison .product-header > .col {
  padding: 0; }

.product-tile .tile-body .compare {
  font-size: 0.875em; }

.table-striped-column td:nth-of-type(odd) {
  background-color: #eee; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 15px;
    padding-right: 15px; } }

.secondary-bar.hide {
  display: none; }

.secondary-bar.show {
  display: block; }

@media (max-width: 991.98px) {
  .grid-header .result-count,
  .content-grid-header .result-count {
    padding-bottom: 0.938em; } }

.refinement-bar {
  transition: all 0.3s ease; }
  .refinement-bar.hide {
    width: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0; }

@media (min-width: 769px) {
  .refinements {
    margin-top: 0.625rem;
    padding-right: 1rem; } }

.refinements ul li {
  padding: 0; }
  .refinements ul li.color-attribute {
    display: list-item;
    margin: 0 0.5rem 0.125rem 0; }
  .refinements ul li.size-attribute {
    margin: 0 0.5rem 0.5rem 0; }
    .refinements ul li.size-attribute button {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0.25rem;
      font-size: 0.75rem; }

.refinement.card {
  margin-bottom: 1.5rem; }
  .refinement.card .card-header {
    padding: 0;
    border-bottom: 1px solid var(--color-grey3); }
    .refinement.card .card-header .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3125rem 0 0.5rem;
      line-height: 1; }
  .refinement.card .refinement-headline {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0; }
  .refinement.card .card-body {
    padding: 0.75rem 0 0; }
  .refinement.card .values {
    margin-bottom: 0;
    padding: 0; }
  .refinement.card.refinement-category li {
    margin-left: 0; }
    .refinement.card.refinement-category li.has-subs > button {
      text-transform: uppercase; }
    .refinement.card.refinement-category li.has-subs:before {
      font-family: 'FontAwesome';
      font-size: 0.875rem;
      content: "\F078";
      position: relative;
      top: -1px;
      line-height: 1; }
    .refinement.card.refinement-category li.selected > button {
      font-weight: 600;
      pointer-events: none; }
  .refinement.card.refinement-category ul ul {
    margin-left: 1rem; }

.filter-bar {
  margin-top: 0.875rem; }
  @media (min-width: 769px) {
    .filter-bar {
      margin-top: 0; } }
  .filter-bar ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin-bottom: 0; }
  .filter-bar li.swatch-filter {
    margin-top: 0; }
    .filter-bar li.swatch-filter button {
      display: flex;
      align-items: center;
      justify-content: center; }
      .filter-bar li.swatch-filter button:before, .filter-bar li.swatch-filter button:after {
        left: unset;
        top: unset; }
  .filter-bar li.filter-value {
    padding: 0.1875rem 0.3125rem; }
    .filter-bar li.filter-value button {
      display: flex;
      align-items: center; }
      .filter-bar li.filter-value button:after {
        top: unset; }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0; }
    .product-grid .col-6 {
      padding-left: 15px;
      padding-right: 15px; } }

.grid-tile-wrapper {
  margin-bottom: 0.5rem; }
  @media (min-width: 769px) {
    .grid-tile-wrapper {
      padding: 0.625rem 0.9375rem; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

.refinement.card {
  margin-bottom: 0; }
  .refinement.card .card-header {
    padding: 0;
    border: solid var(--color-grey);
    border-width: 0.0625rem 0 0; }
    .refinement.card .card-header .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      text-align: left;
      padding: 0.875rem 0;
      line-height: 1;
      color: var(--skin-primary-color); }
  .refinement.card:last-of-type .card-header {
    border-width: 0.0625rem 0; }
  .refinement.card .refinement-headline {
    font-family: "Suisse Book", sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    text-transform: none;
    margin: 0; }
  .refinement.card .card-body {
    padding: 0.75rem 0 1.5rem; }
  .refinement.card .values {
    margin-bottom: 0;
    padding: 0; }
  .refinement.card.refinement-category li {
    margin-left: 0; }
    .refinement.card.refinement-category li.has-subs > button {
      font-family: "Suisse Bold", "Suisse Book", sans-serif;
      font-weight: 700;
      text-transform: none; }
    .refinement.card.refinement-category li.has-subs:before {
      font-family: 'FontAwesome';
      font-size: 0.875rem;
      content: "\F107";
      position: relative;
      top: -1px;
      line-height: 1; }
    .refinement.card.refinement-category li.selected > button {
      pointer-events: none; }
  .refinement.card.refinement-category ul ul {
    margin-left: 1rem; }

.refinement-bar-header {
  position: relative; }

.secondary-bar {
  text-align: center; }
  @media (min-width: 768px) {
    .secondary-bar {
      text-align: left; } }
  .secondary-bar button.reset {
    float: none;
    text-transform: none;
    font-family: "Suisse Book", sans-serif;
    font-weight: 400;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 767.98px) {
      .secondary-bar button.reset {
        font-size: 0.75rem;
        line-height: 1.375rem;
        padding: 0.4375rem 5.90625rem; } }

/*Search Tabs Customization*/
.search-results > .search-nav {
  justify-content: space-around;
  border-bottom: none; }
  .search-results > .search-nav .nav-item {
    margin: 0rem 1.3125rem; }
  .search-results > .search-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
    font-family: "Documenta Regular", serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.09375rem;
    color: var(--color-secondary); }
    .search-results > .search-nav .nav-link.active {
      border-bottom-width: 0.125rem;
      color: var(--color-black); }
    .search-results > .search-nav .nav-link:focus, .search-results > .search-nav .nav-link:hover {
      background-color: transparent;
      color: var(--color-black) !important; }

.filter-results,
.sort-toggle {
  color: #272728; }
